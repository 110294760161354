import React from 'react'
import '../styles/menuTarjetasBlog.scss'
import { useStaticQuery, graphql, Link } from "gatsby";

export const query = graphql`
  query {
    allStrapiProyectosecurity(limit: 2, sort: {order: DESC, fields: published_at}) {
      edges {
        node {
          titulo
          slug
        }
      }
    }
  }  
`

function close() {
  document.body.style.overflow = 'unset';
  //document.getElementsByClassName('logo-primera')[0].style.fill = '#1f1f1f'
}

export default function MenuTarjetasBlog() {

  const data = useStaticQuery(query);

  return (

    <>
      {data.allStrapiProyectosecurity.edges.map(({ node }, key) => (
        <article key={key} className="tarjeta-menu-blog">
          <div className="cuerpo-tarjeta">
            <h3 className="titulo-blog">{node.titulo}</h3>
            <p className="leer-mas"><Link to={`/proyectos/${node.slug}/`} onClick={() => close()}>Leer más</Link></p>
          </div>
        </article>
      ))}
    </>
  );
}