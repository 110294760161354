import React from 'react';
import Menu from './menu';
import '../styles/header.scss';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from 'react-icons';

// const ventana = typeof window !== 'undefined';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //prevScrollpos: ventana.pageYOffset,
            visible: true,
            open: false,
        };
    }


    // Adds an event listener when the component is mount.
    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    // // Remove the event listener when the component is unmount.
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }

    // // Hide or show the menu.
    // handleScroll = () => {
    //     const { prevScrollpos } = this.state;

    //     const currentScrollPos = window.pageYOffset;
    //     const visible = prevScrollpos > currentScrollPos;

    //     this.setState({
    //         prevScrollpos: currentScrollPos,
    //         visible,
    //     });
    // };

    render() {
        const altLogo = () => {
            switch (this.props.loc?.pathname) {
                case "/":
                    return 'Empresa de seguridad informática - Ciberseguridad para empresas';
                /*SERVICIOS*/
                //Ciberseguridad
                case "/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/":
                    return 'Auditorías de ciberseguridad - Servicios de seguridad informática';

                case "/servicios/ciberseguridad/hacking-etico/analisis-vulnerabilidades/":
                    return 'Análisis de vulnerabilidades web - Empresa de ciberseguridad';

                case "/servicios/ciberseguridad/hacking-etico/red-team/":
                    return 'Servicio Red Team - Análisis de vulnerabilidad web';

                case "/servicios/ciberseguridad/defensivos/securizacion-dominios/":
                    return 'Securización de dominios - Empresa de seguridad informática';

                case "/servicios/ciberseguridad/defensivos/formacion-concienciacion/":
                    return 'Formación en ciberseguridad para empresas';

                case "/servicios/ciberseguridad/defensivos/proteccion-dato/":
                    return 'Servicio de protección de datos - Empresa de ciberseguridad';

                case "/servicios/ciberseguridad/ciberseguridad-industrial/auditoria-ot-dirigida/":
                    return 'Auditoría OT dirigida - Auditorías de ciberseguridad';

                //Sistemas informáticos
                case "/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/":
                    return 'Sistemas informáticos avanzados - Servicio de hosting seguro';

                case "/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/":
                    return 'Outsourcing tecnológico';

                //Cloud
                case "/servicios/cloud/hosting-web/":
                    return 'Hosting seguro - Servicio de alojamiento web';

                case "/servicios/cloud/vps/":
                    return 'Servidor virtual privado (VPS)';

                case "/servicios/cloud/servidor-dedicado/":
                    return 'Servidor dedicado - Servicio alojamiento web';

                case "/servicios/cloud/backup/":
                    return 'Copia de seguridad en la nube - Servicio Backup';

                //Compliance
                case "/servicios/compliance/":
                    return 'Compliance - Empresa de seguridad informática';

                /*CLOUD ROBUSTO*/
                case "/cloud-robusto/":
                    return 'Cloud Robusto Bullhost';

                /*PROYECTOS*/
                case "/proyectos/":
                    return 'Proyectos Bullhost';

                /*FABRICANTES*/
                case "/fabricantes/":
                    return 'Fabricantes Bullhost';

                /*BLOG*/
                case "/blog/":
                    return 'Blog Bullhost - Empresa de ciberseguridad';

                /*CONTACTO*/
                case "/contacto/":
                    return 'Contacto Bullhost - Empresa de ciberseguridad';

                default:
                    return 'Bullhost - Empresa de seguridad informática';
            }
        }
        return (
            <>
                <nav
                    className={classnames('barra-superior container', {
                        'navbar--hidden': !this.state.visible,
                    })}
                >
                    <div>
                        <Link to="/" className="logo" onClick={() => this.cerrarMenu()}>
                            {/* <img id="logo-bullhost" src="/img/bullhost-security-black.svg" alt={altLogo()} /> */}
                            <h1><img id="logo-bullhost" src="/img/bullhost-security-black.svg" alt={altLogo()} /></h1>
                        </Link>
                    </div>
                    <div className="menu-iconos">
                        <IconContext.Provider value={{ size: '2.7rem' }}>
                            <div className="icono-buscar" aria-label="Buscar" role="button" tabIndex="0" onKeyDown={() => this.openBuscar()} onClick={() => this.openBuscar()}>
                                <AiOutlineSearch height="3rem" width="3rem" />
                            </div>
                        </IconContext.Provider>
                        <div
                            role="button"
                            tabIndex="0"
                            aria-label="Abrir o cerrar menú"
                            className={`menu-trigger ${this.state.open ? 'open' : ''}`}
                            onKeyDown={() => this.toggleMenu()}
                            onClick={() => this.toggleMenu()}
                        >
                            <div className="hamburguesa">
                                <div className="linea lin1"></div>
                                <div className="linea lin2"></div>
                                <div className="linea lin3"></div>
                            </div>
                        </div>
                    </div>
                    <Menu ref={(el) => (this.childMenu = el)} />
                </nav>
            </>
        );
    }

    openBuscar() {
        const ventana = document.getElementById('ventana-buscar');
        ventana.classList.add('activo');
    }

    toggleMenu() {
        this.childMenu.abreCierra();
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
    }

    cerrarMenu() {
        this.childMenu.close();
        this.setState({ open: false });
    }
}

export default Header;
