import React, { useImperativeHandle, useRef } from 'react';
import '../styles/menu.scss';
import { StaticImage } from 'gatsby-plugin-image';
import MenuTarjetasBlog from '../components/menuTarjetasBlog';
import MenuTarjetasProyecto from '../components/menuTarjetasProyecto';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'gatsby';

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activo: false,
        };
    }

    mostrarSubmenu(id, e) {
        const lista = document.querySelectorAll('.menu-contenido-grupo');
        const links = document.querySelectorAll('.link-menu');
        const listaServicios = document.querySelectorAll('.lista-servicios');
        const linkServ = document.querySelectorAll('.link-servicios');
        // e.target.closest('li').classList.add("activo");

        [...links].map((li) => {
            if (li.id !== `link-${id}`) {
                li.classList.remove('activo');
            } else {
                li.classList.add('activo');
            }
            return '';
        });

        [...lista].map((el) => {
            if (el.id !== id) {
                el.classList.remove('activo');
            } else {
                el.classList.add('activo');
            }
            return '';
        });

        [...listaServicios].map((el) => {
            el.classList.remove('activo');
            return '';
        });

        [...linkServ].map((li) => {
            li.classList.remove('activo');
            return '';
        });
    }

    mostrarSubmenuServicios(id) {
        const listaServ = document.querySelectorAll('.lista-servicios');
        const linkServ = document.querySelectorAll('.link-servicios');

        [...listaServ].map((el) => {
            if (el.id !== id) {
                el.classList.remove('activo');
            } else {
                el.classList.add('activo');
            }
            return '';
        });

        [...linkServ].map((li) => {
            if (li.id !== `link-${id}`) {
                li.classList.remove('activo');
            } else {
                li.classList.add('activo');
            }
            return '';
        });
    }

    mostrarSubmenuMovil(id) {
        const panel = document.getElementById(id);

        if (panel.classList.contains('activo')) {
            panel.classList.remove('activo');
        } else {
            panel.classList.add('activo');
        }
    }

    render() {
        return (
            <div className={`menu container ${this.state.open ? 'open' : ''}`}>
                <div className="menu-max">
                    <div className="menu-interior">
                        <div className="menu-interior-col izq">
                            <ul className="enlaces-menu">
                                <li id="link-cloud-robusto" className="link-menu">
                                    <Link to="/cloud-robusto/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu('cloud-robusto', e)} data-nav="cloud-robusto">
                                        Cloud robusto
                                    </Link>
                                </li>
                                <li className="servicios-movil" onKeyDown={() => this.mostrarSubmenuMovil('panel-servicios')} onClick={() => this.mostrarSubmenuMovil('panel-servicios')}>
                                    Servicios <MdKeyboardArrowRight />
                                </li>
                                <li
                                    id="link-servicios"
                                    className="servicios-pc link-menu"
                                    onKeyDown={(e) => this.mostrarSubmenu('servicios', e)}
                                    onClick={(e) => this.mostrarSubmenu('servicios', e)}
                                    onMouseEnter={(e) => this.mostrarSubmenu('servicios', e)}
                                >
                                    Servicios
                                </li>
                                <li id="link-proyectos" className="link-menu">
                                    <Link to="/proyectos/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu('proyectos', e)}>
                                        Proyectos
                                    </Link>
                                </li>
                                <li id="link-fabricantes" className="link-menu">
                                    <Link to="/fabricantes/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu('fabricantes', e)}>
                                        Fabricantes
                                    </Link>
                                </li>
                                {/* <li id="link-recursos" className="link-menu"><Link to="/recursos/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu("recursos", e)}>Recursos</Link></li> */}
                                <li id="link-blog" className="link-menu">
                                    <Link to="/blog/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu('blog', e)}>
                                        Blog
                                    </Link>
                                </li>
                                <li id="link-contacto" className="link-menu">
                                    <Link to="/contacto/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu('contacto', e)}>
                                        Contacto
                                    </Link>
                                </li>
                                {/* <li id="link-empleo" className="link-menu"><Link to="/empleo/" onClick={() => this.close()} onMouseEnter={(e) => this.mostrarSubmenu("empleo", e)}>Empleo</Link></li> */}
                            </ul>
                        </div>
                        <div className="submenus-movil">
                            <div className="panel-movil" id="panel-servicios">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-servicios')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-servicios')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-ciberseguridad')} onClick={() => this.mostrarSubmenuMovil('panel-ciber-servicios')}>
                                            Ciberseguridad <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-sistemas')} onClick={() => this.mostrarSubmenuMovil('panel-sistemas')}>
                                            Sistemas informáticos avanzados <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-cloud')} onClick={() => this.mostrarSubmenuMovil('panel-cloud')}>
                                            Cloud <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3>
                                            <Link to="/servicios/compliance/" onClick={() => this.close()}>
                                                Compliance
                                            </Link>
                                        </h3>
                                    </li>
                                    <li>
                                        <h3>
                                            <a href="https://bullhost.agency/" target="_blank" rel="noreferrer noopener" onClick={() => this.close()}>
                                                Marketing y Web
                                            </a>
                                        </h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-ciber-servicios">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-servicios')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-ciber-servicios')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-ofensivos')} onClick={() => this.mostrarSubmenuMovil('panel-ciber-ofensivos')}>
                                            Hacking ético: Ofensivos <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-defensivos')} onClick={() => this.mostrarSubmenuMovil('panel-ciber-defensivos')}>
                                            Defensivos <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3 onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-ofensivos')} onClick={() => this.mostrarSubmenuMovil('panel-ciber-industrial')}>
                                            Ciber Industrial <MdKeyboardArrowRight />
                                        </h3>
                                    </li>
                                    <li>
                                        <h3>
                                            <Link to="/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/" onClick={() => this.close()}>
                                                Integración Soluciones
                                            </Link>
                                        </h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-ciber-ofensivos">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-ofensivos')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-ciber-ofensivos')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    {/* <li><Link to="/servicios/ciberseguridad/hacking-etico-ofensivos/" onClick={() => this.close()}>Hacking ético: Ofensivos</Link></li> */}
                                    <li>
                                        <Link to="/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/" onClick={() => this.close()}>
                                            Auditorías Ciberseguridad
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servicios/ciberseguridad/hacking-etico/analisis-vulnerabilidades/" onClick={() => this.close()}>
                                            Análisis de vulnerabilidades
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/ciberseguridad/hacking-etico-ofensivos/test-de-intrusion/" onClick={() => this.close()}>Test de intrusión</Link></li> */}
                                    <li>
                                        <Link to="/servicios/ciberseguridad/hacking-etico/red-team/" onClick={() => this.close()}>
                                            Red Team
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-ciber-defensivos">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-defensivos')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-ciber-defensivos')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/" onClick={() => this.close()}>Defensivos</Link></li> */}
                                    <li>
                                        <Link to="/servicios/ciberseguridad/defensivos/securizacion-dominios/" onClick={() => this.close()}>
                                            Securización de dominios
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/monitorizacion-soc/" onClick={() => this.close()}>Monitorización SOC</Link></li> */}
                                    <li>
                                        <Link to="/servicios/ciberseguridad/defensivos/formacion-concienciacion/" onClick={() => this.close()}>
                                            Formación y concienciación
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servicios/ciberseguridad/defensivos/proteccion-dato/" onClick={() => this.close()}>
                                            Protección del dato
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/vigilancia-digital/" onClick={() => this.close()}>Vigilancia Digital</Link></li> */}
                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/respuesta-ante-incidentes/" onClick={() => this.close()}>Respuesta ante incidentes</Link></li> */}
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-ciber-industrial">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-ciber-industrial')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-ciber-industrial')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    {/* <li><Link to="/servicios/ciberseguridad/ciberseguridad-industrial/" onClick={() => this.close()}>Ciber Industrial</Link></li> */}
                                    <li>
                                        <Link to="/servicios/ciberseguridad/ciberseguridad-industrial/auditoria-ot-dirigida/" onClick={() => this.close()}>
                                            Auditoría OT dirigida
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/ciberseguridad/ciberseguridad-industrial/monitorizacion-red-ot/" onClick={() => this.close()}>Monitorización red OT</Link></li> */}
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-cloud">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-cloud')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-cloud')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    <li>
                                        <Link to="/servicios/cloud/hosting-web/" onClick={() => this.close()}>
                                            Hosting web Linux y Windows
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servicios/cloud/vps/" onClick={() => this.close()}>
                                            Servidor Virtual Privado
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servicios/cloud/servidor-dedicado/" onClick={() => this.close()}>
                                            Servidor Dedicado
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servicios/cloud/backup/" onClick={() => this.close()}>
                                            Copias de seguridad: Backups
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/cloud/sistemas-almacenamiento-documental/" onClick={() => this.close()}>Sistemas de almacenamiento documental</Link></li> */}
                                </ul>
                            </div>
                            <div className="panel-movil" id="panel-sistemas">
                                <ul>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="flecha-volver"
                                        onKeyDown={() => this.mostrarSubmenuMovil('panel-sistemas')}
                                        onClick={() => this.mostrarSubmenuMovil('panel-sistemas')}
                                    >
                                        <p>
                                            <MdKeyboardArrowLeft /> Volver
                                        </p>
                                    </div>
                                    <li>
                                        <Link to="/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/" onClick={() => this.close()}>
                                            Sistemas informáticos
                                        </Link>
                                    </li>
                                    {/* <li><Link to="/servicios/sistemas-informaticos-avanzados/gestion-de-redes-y-comunicaciones/" onClick={() => this.close()}>Gestión de redes y comunicaciones</Link></li> */}
                                    <li>
                                        <Link to="/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/" onClick={() => this.close()}>
                                            Outsourcing tecnológico
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="menu-interior-col der">
                            <div className="menu-contenido-grupo" id="cloud-robusto">
                                <div className="c-robusto">
                                    <h2>Cloud robusto = CPD local + Equipo certificado y experimentado + Ciberseguridad</h2>
                                    <Link className="mas" to="/cloud-robusto/" onClick={() => this.close()}>
                                        Saber más
                                    </Link>
                                </div>
                            </div>

                            <div className="menu-contenido-grupo" id="servicios">
                                <div className="servicios">
                                    <div className="servicios-col izq">
                                        <ul>
                                            <li id="link-servicios-ciber" className="link-servicios" onMouseEnter={(e) => this.mostrarSubmenuServicios('servicios-ciber', e)}>
                                                Ciberseguridad
                                            </li>
                                            <li id="link-servicios-sistemas" className="link-servicios" onMouseEnter={(e) => this.mostrarSubmenuServicios('servicios-sistemas', e)}>
                                                Sistemas informáticos avanzados
                                            </li>
                                            <li id="link-servicios-cloud" className="link-servicios" onMouseEnter={(e) => this.mostrarSubmenuServicios('servicios-cloud', e)}>
                                                Cloud
                                            </li>
                                            <li id="link-servicios-compliance" className="link-servicios" onMouseEnter={(e) => this.mostrarSubmenuServicios('servicios-compliance', e)}>
                                                <Link to="/servicios/compliance/" onClick={() => this.close()}>
                                                    Compliance
                                                </Link>
                                            </li>
                                            <li id="link-servicios-agency" className="link-servicios" onMouseEnter={(e) => this.mostrarSubmenuServicios('servicios-agency', e)}>
                                                <a href="https://bullhost.agency" onClick={() => this.close()}>
                                                    Marketing y Web
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="servicios-col der">
                                        <div id="servicios-ciber" className="lista-servicios servicios-ciber">
                                            <div>
                                                <h3>Hacking ético: Ofensivos</h3>
                                                {/* <h3><Link to="/servicios/ciberseguridad/hacking-etico/" onClick={() => this.close()}>Hacking ético: Ofensivos</Link></h3> */}
                                                <ul>
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/" onClick={() => this.close()}>
                                                            Auditorías Ciberseguridad
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/hacking-etico/analisis-vulnerabilidades/" onClick={() => this.close()}>
                                                            Análisis de vulnerabilidades
                                                        </Link>
                                                    </li>
                                                    {/* <li><Link to="/servicios/ciberseguridad/hacking-etico/test-intrusion/" onClick={() => this.close()}>Test de intrusión</Link></li> */}
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/hacking-etico/red-team/" onClick={() => this.close()}>
                                                            Red Team
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h3>Defensivos</h3>
                                                {/* <h3><Link to="/servicios/ciberseguridad/defensivos/" onClick={() => this.close()}>Defensivos</Link></h3> */}
                                                <ul>
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/defensivos/securizacion-dominios/" onClick={() => this.close()}>
                                                            Securización de dominios
                                                        </Link>
                                                    </li>
                                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/monitorizacion-soc/" onClick={() => this.close()}>Monitorización SOC</Link></li> */}
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/defensivos/formacion-concienciacion/" onClick={() => this.close()}>
                                                            Formación y concienciación
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/defensivos/proteccion-dato/" onClick={() => this.close()}>
                                                            Protección del dato
                                                        </Link>
                                                    </li>
                                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/vigilancia-digital/" onClick={() => this.close()}>Vigilancia Digital</Link></li> */}
                                                    {/* <li><Link to="/servicios/ciberseguridad/defensivos/respuesta-incidentes/" onClick={() => this.close()}>Respuesta ante incidentes</Link></li> */}
                                                </ul>
                                            </div>
                                            <div>
                                                <h3>Ciber Industrial</h3>
                                                {/* <h3><Link to="/servicios/ciberseguridad/ciberseguridad-industrial/" onClick={() => this.close()}>Ciber Industrial</Link></h3> */}
                                                <ul>
                                                    <li>
                                                        <Link to="/servicios/ciberseguridad/ciberseguridad-industrial/auditoria-ot-dirigida/" onClick={() => this.close()}>
                                                            Auditoría OT dirigida
                                                        </Link>
                                                    </li>
                                                    {/* <li><Link to="/servicios/ciberseguridad/ciberseguridad-industrial/monitorizacion-red-ot/" onClick={() => this.close()}>Monitorización red OT</Link></li> */}
                                                </ul>
                                            </div>
                                            <div>
                                                <h3>
                                                    <Link to="/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/" onClick={() => this.close()}>
                                                        Integración Soluciones
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                        <div id="servicios-cloud" className="lista-servicios servicios-cloud">
                                            <h3>Cloud</h3>
                                            <ul>
                                                <li>
                                                    <Link to="/servicios/cloud/hosting-web/" onClick={() => this.close()}>
                                                        Hosting web Linux y Windows
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/servicios/cloud/vps/" onClick={() => this.close()}>
                                                        Servidor Virtual Privado
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/servicios/cloud/servidor-dedicado/" onClick={() => this.close()}>
                                                        Servidor Dedicado
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/servicios/cloud/backup/" onClick={() => this.close()}>
                                                        Copias de seguridad: Backups
                                                    </Link>
                                                </li>
                                                {/* <li><Link to="/servicios/cloud/sistemas-almacenamiento-documental/" onClick={() => this.close()}>Sistemas de almacenamiento documental</Link></li> */}
                                            </ul>
                                        </div>
                                        <div id="servicios-sistemas" className="lista-servicios servicios-sistemas">
                                            <h3>Sistemas informáticos avanzados</h3>
                                            <ul>
                                                <li>
                                                    <Link to="/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/" onClick={() => this.close()}>
                                                        Sistemas informáticos
                                                    </Link>
                                                </li>
                                                {/* <li><Link to="/servicios/sistemas-informaticos-avanzados/gestion-de-redes-y-comunicaciones/" onClick={() => this.close()}>Gestión de redes y comunicaciones</Link></li> */}
                                                <li>
                                                    <Link to="/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/" onClick={() => this.close()}>
                                                        Outsourcing tecnológico
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="menu-contenido-grupo" id="proyectos">
                                <div className="proyectos">
                                    <div className="texto">
                                        <h2>Revisa nuestros casos de éxito</h2>
                                        <Link className="mas" to="/proyectos/" onClick={() => this.close()}>
                                            Explorar proyectos
                                        </Link>
                                    </div>
                                    <div className="cajas">
                                        <MenuTarjetasProyecto />
                                    </div>
                                </div>
                            </div>

                            <div className="menu-contenido-grupo" id="fabricantes">
                                <div className="fabricantes">
                                    <div className="texto">
                                        <h2>Trabajamos con las principales marcas</h2>
                                        <Link className="mas" to="/fabricantes/" onClick={() => this.close()}>
                                            Saber más
                                        </Link>
                                    </div>
                                    <div className="marcas">
                                        <div className="logo-fab">
                                            <img src="/img/logos-fabricantes/kaspersky-logo.svg" alt="Kaspersky"></img>
                                        </div>
                                        <div className="logo-fab">
                                            <img src="/img/logos-fabricantes/sophos-logo.svg" alt="Sophos"></img>
                                        </div>
                                        <div className="logo-fab">
                                            <StaticImage src="../../static/img/logos-fabricantes/paloalto-logo.png" alt="Palo Alto" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="menu-contenido-grupo" id="recursos">
                <div className="recursos">
                  <h2>Examina nuestros recursos para obtener más información sobre lo que ofrecemos</h2>
                  <Link className="mas" to="/recursos/" onClick={() => this.close()}>Saber más</Link>
                </div>
              </div> */}

                            <div className="menu-contenido-grupo" id="blog">
                                <div className="blog">
                                    <div className="texto">
                                        <h2>Estate al día con nuestras noticias</h2>
                                        <Link className="mas" to="/blog/" onClick={() => this.close()}>
                                            Explorar noticias
                                        </Link>
                                    </div>
                                    <div className="cajas">
                                        <MenuTarjetasBlog />
                                    </div>
                                </div>
                            </div>

                            <div className="menu-contenido-grupo" id="contacto">
                                <div className="contacto">
                                    <div className="texto">
                                        <h2>Trabajemos juntos, ponte en contacto</h2>
                                        <Link className="mas" to="/contacto/" onClick={() => this.close()}>
                                            Saber más
                                        </Link>
                                    </div>
                                    <div className="cajas">
                                        <div className="contacto-caja">
                                            <h3>Visítanos</h3>
                                            <p>
                                                <a href="https://goo.gl/maps/ge53LSc5eigRjbss8" target="_blank" rel="noopener noreferrer">
                                                    Paseo Landabarri 2<br />
                                                    48940, Leioa (Bizkaia)
                                                </a>
                                            </p>
                                            <p>
                                                <a href="https://goo.gl/maps/QuYKbrybF5SVq81h6" target="_blank" rel="noopener noreferrer">
                                                    Travessera de mar 1
                                                    <br />
                                                    17770 Borrassà (Girona)
                                                </a>
                                            </p>
                                        </div>
                                        <div className="contacto-caja">
                                            <h3>Llámanos</h3>
                                            <p>
                                                <a href="tel:+34946941377">+34 946 94 13 77</a>
                                            </p>
                                        </div>
                                        <div className="contacto-caja">
                                            <h3>Escríbenos</h3>
                                            <p>
                                                <a href="mailto:hola@bullhost.es">hola@bullhost.es</a>
                                            </p>
                                            <p>
                                                <a href="mailto:soporte@bullhost.es">soporte@bullhost.es</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="menu-contenido-grupo" id="empleo">
                <div className="empleo">
                  <div className="texto">
                    <h2>¿Quieres trabajar con nosotros?</h2>
                    <Link className="mas" to="/empleo/" onClick={() => this.close()}>Rellena el formulario</Link>
                  </div>
                </div>
            </div>}*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    close() {
        this.setState({ open: false });
        document.body.style.overflow = 'unset';
        //document.getElementsByClassName('logo-primera')[0].style.fill = '#1f1f1f';
    }

    abreCierra() {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));

        if (!this.state.open) {
            document.body.style.overflow = 'hidden';
            // document.getElementsByClassName('logo-primera')[0].style.fill = '#fff'
            document.getElementById('logo-bullhost').style.filter = 'invert(100)';
        } else {
            document.body.style.overflow = 'unset';
            // document.getElementsByClassName('logo-primera')[0].style.fill = '#1f1f1f'
            document.getElementById('logo-bullhost').style.filter = 'invert(0)';
            const lista = document.querySelectorAll('.activo');
            [...lista].map((el) => {
                el.classList.remove('activo');
                return '';
            });
        }
    }
}

export default React.forwardRef((props, ref) => {
    const menuRef = useRef();

    useImperativeHandle(ref, () => ({
        abreCierra() {
            menuRef.current.abreCierra();
        },
        close() {
            menuRef.current.close();
        },
    }));

    return <Menu ref={menuRef} {...props} />;
});
