import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

function Seo({ description, lang, meta, title, imagen, pathname, robots }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            autor
            image
            url
            robots
          }
        }
      }
    `
  )

  let titulo = ""
  let descripcion = ""
  let tituloRRSS = ""

  switch (pathname) {

    case "/":
      titulo = 'Empresa de seguridad informática - Ciberseguridad para empresas';
      descripcion = 'Bullhost, ofrecemos servicios de seguridad informática para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos.';
      tituloRRSS = 'Empresa de ciberseguridad - Bullhost';
      break;

    /*SERVICIOS*/
    //Ciberseguridad
    case "/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/":
      titulo = 'Auditorías de ciberseguridad - Servicios de seguridad informática';
      descripcion = 'Auditorías de ciberseguridad para prevenir los riesgos que afectan a los activos críticos de tu negocio.';
      tituloRRSS = 'Auditorías de ciberseguridad - Servicios de seguridad informática';
      break;

    case "/servicios/ciberseguridad/hacking-etico/analisis-vulnerabilidades/":
      titulo = 'Análisis de vulnerabilidades web - Empresa de ciberseguridad';
      descripcion = 'Realizamos análisis de vulnerabilidades web para certificar el grado de seguridad en la aplicación y valorar si se cumplen las politicas de seguridad de las TIC.';
      tituloRRSS = 'Análisis de vulnerabilidades web - Empresa de ciberseguridad';
      break;

    case "/servicios/ciberseguridad/hacking-etico/red-team/":
      titulo = 'Servicio Red Team - Análisis de vulnerabilidad web';
      descripcion = 'Simulamos un ataque dirigido a tu organización para comprobar qué posibilidad existe de tener acceso a los sistemas.';
      tituloRRSS = 'Servicio Red Team - Análisis de vulnerabilidad web';
      break;

    case "/servicios/ciberseguridad/defensivos/securizacion-dominios/":
      titulo = 'Securización de dominios - Empresa de seguridad informática';
      descripcion = 'En Bullhost securizamos tu dominio modificando y protegiendo determinados parámetros en el mismo que impiden que alguien se haga pasar por ti y con tu email.';
      tituloRRSS = 'Securización de dominios - Empresa de seguridad informática';
      break;

    case "/servicios/ciberseguridad/defensivos/formacion-concienciacion/":
      titulo = 'Formación en ciberseguridad para empresas';
      descripcion = 'En Bullhost disponemos de un servicio de concienciación y formación en ciberseguridad pensado para que las organizaciones puedan conocer el nivel de riesgo que suponen sus trabajadores.';
      tituloRRSS = 'Formación en ciberseguridad para empresas';
      break;

    case "/servicios/ciberseguridad/defensivos/proteccion-dato/":
      titulo = 'Servicio de protección de datos - Empresa de ciberseguridad';
      descripcion = 'En Bullhost trabajamos nuestro servicio de protección del dato de la mano y con la tecnología de uno de los mas relevantes fabricantes específicos: Safetica.';
      tituloRRSS = 'Servicio de protección de datos - Empresa de ciberseguridad';
      break;

    case "/servicios/ciberseguridad/ciberseguridad-industrial/auditoria-ot-dirigida/":
      titulo = 'Auditoría OT dirigida - Auditorías de ciberseguridad';
      descripcion = 'Auditoría OT dirigida para poder evaluar el nivel de seguridad de las barreras de protección y de la infraestructura de tu entorno industrial.';
      tituloRRSS = 'Auditoría OT dirigida - Auditorías de ciberseguridad';
      break;

    //Sistemas informáticos
    case "/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/":
      titulo = 'Sistemas informáticos avanzados - Servicio de hosting seguro';
      descripcion = 'Bullhost mantiene y gestiona todas la insfraestructuras de nuestros clientes, tanto las propias en sus instalaciones como aquellas soluciones en nuestra nube.';
      tituloRRSS = 'Sistemas informáticos avanzados - Servicio de hosting seguro';
      break;

    case "/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/":
      titulo = 'Outsourcing tecnológico';
      descripcion = 'Servicio outsourcing tecnológico: Evitarás pérdidas de tiempo de aprendizaje e inactividad, ahorrarás costes en personal especializado y aumentarás la productividad.';
      tituloRRSS = 'Outsourcing tecnológico';
      break;

    //Cloud
    case "/servicios/cloud/hosting-web/":
      titulo = 'Hosting seguro - Servicio de alojamiento web';
      descripcion = 'Ofrecemos nuestro servicio de alojamiento y correo para proyectos web, desde el blog mas sencillo hasta el ecommerce más potente. Servicio de hosting securizado.';
      tituloRRSS = 'Hosting seguro - Servicio de alojamiento web';
      break;


    case "/servicios/cloud/vps/":
      titulo = 'Servidor virtual privado (VPS)';
      descripcion = 'La solución perfecta para disfrutar de las ventajas de una máquina dedicada a un precio más asequible que otras soluciones.';
      tituloRRSS = 'Servidor virtual privado (VPS)';
      break;

    case "/servicios/cloud/servidor-dedicado/":
      titulo = 'Servidor dedicado - Servicio alojamiento web';
      descripcion = 'Ofrecemos servidores dedicados para proyectos exigentes o entornos críticos, como un software de gestión (ERP).';
      tituloRRSS = 'Servidor dedicado - Servicio alojamiento web';
      break;

    case "/servicios/cloud/backup/":
      titulo = 'Copia de seguridad en la nube - Servicio Backup';
      descripcion = 'Ofrecemos backup en la nube mediante un proceso automatizado responsable de crear una copia de seguridad online en nuestro Cloud Robusto.';
      tituloRRSS = 'Copia de seguridad en la nube - Servicio Backup';
      break;

    //Compliance
    case "/servicios/compliance/":
      titulo = 'Compliance - Empresa de seguridad informática';
      descripcion = 'Contamos con consultores con un alto expertise y reconocimiento en el mercado que garantizan la implantación o adaptación de las normativas y estándares más habituales.';
      tituloRRSS = 'Compliance - Empresa de seguridad informática';
      break;


    /*CLOUD ROBUSTO*/
    case "/cloud-robusto/":
      titulo = 'Cloud Robusto Bullhost';
      descripcion = 'Centro de datos propio ubicado en Euskadi, con altas prestaciones de seguridad, protocolos personalizados de tarjetas de acceso, de escaneo biométrico y vigilancia interior y exterior en el edificio.';
      tituloRRSS = 'Cloud Robusto Bullhost';
      break;

    /*PROYECTOS*/
    case "/proyectos/":
      titulo = 'Proyectos Bullhost';
      descripcion = 'Proyectos Bullhost. Ofrecemos servicios de seguridad informática para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos.';
      tituloRRSS = 'Proyectos Bullhost';
      break;


    /*FABRICANTES*/
    case "/fabricantes/":
      titulo = 'Fabricantes Bullhost';
      descripcion = 'Trabajamos con los mejores fabricantes del mercado, siendo un referente para ellos. Bullhost, se erige como partner potente de las mejores marcas del mercado internacional.';
      tituloRRSS = 'Fabricantes Bullhost';
      break;


    /*BLOG*/
    case "/blog/":
      titulo = 'Blog Bullhost - Empresa de ciberseguridad';
      descripcion = 'Blog Bullhost. Ofrecemos servicios de seguridad informática para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos.';
      tituloRRSS = 'Blog Bullhost - Empresa de ciberseguridad';
      break;


    /*CONTACTO*/

    case "/contacto/":
      titulo = 'Contacto Bullhost - Empresa de ciberseguridad';
      descripcion = 'Contacta con nosotros. Ofrecemos servicios de seguridad informática para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos.';
      tituloRRSS = 'Contacto Bullhost - Empresa de ciberseguridad';
      break;


    default:
      titulo = 'Bullhost - Empresa de seguridad informática';
      descripcion = 'En Bullhost ofrecemos servicios de seguridad informática para empresas, servicios de hosting, almacenamiento en la nube y servicio de protección de datos.';
      tituloRRSS = 'Bullhost - Empresa de seguridad informática';
      break;
  }

  const metaDescription = descripcion || site.siteMetadata.description
  // const defaultTitle = site.siteMetadata?.title
  const image = imagen || site.siteMetadata.image
  const url = `${site.siteMetadata.url}${pathname || ""}`
  const robotsseo = robots || site.siteMetadata.robots
  const keywords = 'empresa de seguridad informatica, empresa de ciberseguridad, servicios de seguridad informatica, ciberseguridad para empresas, seguridad informatica para empresas, auditorias de ciberseguridad, analisis de vulnerabilidad web, servicio de proteccion de datos, consultoria ciberseguridad, formación en ciberseguridad para empresas, hosting seguro, servicio alojamiento web, servicios de almacenamiento en la nube, copia de seguridad en la nube, servicios en la nube para empresas';

  const bullJsonLD =
    [{

      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      "name": titulo,
      "description": metaDescription,
      "priceRange": "€€€",
      "image": image,
      "telephone": "946 94 13 77",

      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Paseo Landabarri, 2 (Edificio Cosimet)",
        "addressLocality": "Leioa",
        "addressRegion": "Bizkaia",
        "postalCode": "48940"
      },
      "url": url
    },
    {

      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      "name": titulo,
      "description": metaDescription,
      "priceRange": "€€€",
      "image": image,
      "telephone": "946 94 13 77",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Travessera de mar 1",
        "addressLocality": "Borrassà",
        "addressRegion": "Girona",
        "postalCode": "17770"
      },
      "url": url
    }];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titulo}
      titleTemplate={title ? title + ' - Empresa de seguridad informática' : titulo}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `DC.description`,
          content: metaDescription,
        },
        {
          name: `DC.title`,
          content: titulo,
        },
        {
          name: `DC.keywords`,
          content: keywords,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: tituloRRSS,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:site_name`,
          content: "Bullhost",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.autor || ``,
        },
        {
          name: `twitter:title`,
          content: tituloRRSS,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "theme-color",
          content: "#1f1f1f",
        },
        {
          name: "robots",
          content: robotsseo,
        },
        {
          itemprop: "name",
          content: tituloRRSS,
        },
        {
          itemprop: "description",
          content: metaDescription,
        },
        {
          itemprop: "image",
          content: image,
        },
      ].concat(meta)}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: "/img/favicon-blanco.png",
        },
        {
          rel: "apple-touch-icon",
          type: "image/png",
          href: "/img/favicon-blanco.png",
        },
        {
          rel: "canonical",
          href: url,
        },
        {
          rel: "alternate",
          href: url,
          hreflang: "es-ES"
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify(bullJsonLD)}

      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `es-ES`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  link: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
